<template>
    <div class="app-container">
        <el-form ref="form" :model="form" :rules="rules" @submit.native.prevent>
            <el-form-item prop="version" label="版本号" label-width="100px">
                <el-input style="width: 30%" v-model="form.version" placeholder="请输入当前版本号"></el-input>
            </el-form-item>
            <el-form-item prop="packageFileUrl" label="下载链接" label-width="100px">
                <el-input style="width: 30%" v-model="form.packageFileUrl" placeholder="请输入下载链接"></el-input>
            </el-form-item>
            <el-form-item prop="remark" label="升级提示" label-width="100px">
                <vue-tinymce style="width: 30%" v-model="form.remark" :setting="setting" />
            </el-form-item>
            <el-form-item>
                <el-row style="width: 34%" class="submitBtnBox">
                    <el-button type="primary" @click="submit">保存</el-button>
                </el-row>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { appversionQuery, appversionUpdate, appversionAdd } from "@/api/managementCenter";
export default {
    data() {
        return {
            op: "add",
            form: {
                version: "",
                packageFileUrl: "",
                remark: "",
            },
            rules: {
                version: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请输入版本号",
                    },
                ],
                packageFileUrl: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请输入下载链接",
                    },
                ],
            },
            setting: {
                menubar: false,
                toolbar: "",
                toolbar_drawer: "sliding",
                quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
                plugins: "",
                language: "zh_CN", //本地化设置
                height: 350,
            },
        };
    },
    created() {
        this.appVersionInfo();
    },
    methods: {
        appVersionInfo() {
            let params = {
                pageNum: 0,
                pageSize: 10,
            };
            const _this = this;
            const loading = _this.$loading({
                lock: true,
                text: "查询中,请稍后...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
                customClass: "fullScreenLoading",
            });
            appversionQuery(params)
                .then((res) => {
                    loading.close();
                    if (res.data.dataList.length != 0) {
                        let data = res.data.dataList[0];
                        _this.form = {
                            version: data.version,
                            packageFileUrl: data.packageFileUrl,
                            remark: data.remark,
                        };
                        _this.op = data.flowId;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    loading.close();
                });
        },
        submit() {
            const _this = this;
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let params = {
                        version: _this.form.version,
                        packageFileUrl: _this.form.packageFileUrl,
                        remark: _this.form.remark,
                    };
                    const loading = _this.$loading({
                        lock: true,
                        text: "保存中,请稍后...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                        customClass: "fullScreenLoading",
                    });
                    if (_this.op == "add") {
                        appversionAdd(params)
                            .then((res) => {
                                _this.$message({
                                    message: "操作成功",
                                    type: "success",
                                });
                                loading.close();
                                _this.appVersionInfo();
                            })
                            .catch((err) => {
                                loading.close();
                                console.log(err);
                            });
                    } else {
                        params.flowId = _this.op;
                        appversionUpdate(params)
                            .then((res) => {
                                _this.$message({
                                    message: "操作成功",
                                    type: "success",
                                });
                                loading.close();
                                _this.appVersionInfo();
                            })
                            .catch((err) => {
                                loading.close();
                                console.log(err);
                            });
                    }
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.submitBtnBox {
    text-align: center;
}
</style>
