import request from '@/utils/request'

/**
 * 查询轮播图设置列表
 * @param  data 
 * @returns 
 * data:{
    pageNum (integer, optional): 分页页码 ,
    pageSize (integer, optional): 分页每页条数 ,
    state (integer, optional): 状态：1：展示中、2：已禁用 ,
    scene (integer, optional): 场景：1：首页、2：资源详情、3：广告投放 ,
 } 
 */
export function getBannerPageList (data) {
    return request({
        url: '/systembanner/queryPageList',
        method: 'post',
        data
    })
}

/**
 * 查询banner图详情
 * @param  data 
 * @returns 
 * data:{
 *  flowId (string): 流水号 ,
 } 
 */
export function bannerQuery (data) {
    return request({
        url: '/systembanner/query',
        method: 'get',
        params: data
    })
}

/**
 * 新增banner图
 * @param  data 
 * @returns 
 * data:{
    linkUrl (string, optional): 跳转页面地址 ,
    linkUrlState (integer, optional): 跳转页面：0：否、1：是 ,
    pictureUrl (string, optional): 图片URL ,
    scene (integer, optional): 场景：1：首页、2：资源详情、3：广告投放 ,
    state (integer, optional): 状态：1：展示中、2：已禁用 ,
    thumbnailUrl (string, optional): 缩略图URL
 } 
 */
export function bannerAdd (data) {
    return request({
        url: '/systembanner/add',
        method: 'post',
        data
    })
}

/**
 * 更新banner图
 * @param  data 
 * @returns 
 * data:{
 *  flowId (string): 流水号 ,
    linkUrl (string, optional): 跳转页面地址 ,
    linkUrlState (integer, optional): 跳转页面：0：否、1：是 ,
    pictureUrl (string, optional): 图片URL ,
    scene (integer, optional): 场景：1：首页、2：资源详情、3：广告投放 ,
    state (integer, optional): 状态：1：展示中、2：已禁用 ,
    thumbnailUrl (string, optional): 缩略图URL
 } 
 */
export function bannerUpdate (data) {
    return request({
        url: '/systembanner/update',
        method: 'post',
        data
    })
}

/**
 * 删除banner图
 * @param  data 
 * @returns 
 * data:{
 *  flowId (string): 流水号 ,
 } 
 */
export function bannerDelete (data) {
    return request({
        url: '/systembanner/delete',
        method: 'get',
        params: data
    })
}

/**
 * app版本查询
 * @param  data 
 * @returns 
 * data:{
    pageNum (integer, optional): 分页页码 ,
    pageSize (integer, optional): 分页每页条数 ,
 } 
 */
export function appversionQuery (data) {
    return request({
        url: '/appversion/queryList',
        method: 'post',
        data
    })
}

/**
 * app版本新增
 * @param  data 
 * @returns 
 * data:{
    packageFileUrl (string, optional): 升级包路径 ,
    remark (string, optional): 升级提示 ,
    version (string, optional): 版本号
 } 
 */
export function appversionAdd (data) {
    return request({
        url: '/appversion/add',
        method: 'post',
        data
    })
}

/**
 * app版本更新
 * @param  data 
 * @returns 
 * data:{
 *  flowId (integer): 流水号 ,
    packageFileUrl (string, optional): 升级包路径 ,
    remark (string, optional): 升级提示 ,
    version (string, optional): 版本号
 } 
 */
export function appversionUpdate (data) {
    return request({
        url: '/appversion/update',
        method: 'post',
        data
    })
}